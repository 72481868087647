.left-pane-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: var(--exhut-primary);
}

.logo {
  height: 200px;
  text-align: center;
}

.logo img {
  width: 200px;
}

#menu {
  padding: 20px;
}

#menu input[type="button"] {
  background: none;
  color: white;
  font-size: inherit;
  border: none;
  cursor: pointer;
}

#menu ul {
  color: white;
  margin-left: 15%;
  list-style-type: none;
  font-size: large;
}

#menu ul li {
  margin-top: 30px;
}

#menu ul li a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.footer {
  color: white;
  font-size: 15px;
  margin: 5px;
  text-align: center;
  margin: 0px;
  padding: 10px 20px;
  background-color: var(--exhut-primary);
}

.footer div {
  margin: 20px 0px;
}

.footer-copyright {
  font-weight: bold;
}
