#edit-article-container {
  padding: 10px 40px;
  margin: 10px 100px;
}

#edit-article-top-section {
  margin-top: 20px;
}

#edit-article-container .field {
  color: var(--exhut-blue);
  margin-right: 5;
  margin: 0 10px 0px 0px;
  min-width: 120px;
}

#edit-article-sub-heading {
  margin: 2% 0% 0% 0%;
}

#edit-article-version {
  margin: 2% 0%;
}

#editor-component {
  margin: 1%;
  padding: 5% 3%;
  min-height: 700px;
}

#publish-button-container {
  margin: 0px 10px;
}

.editor-card-full-width {
  animation: expand-width 2s;
  width: 100%;
}

.editor-card-half-width {
  animation: compress-width 1s;
  width: 50%;
}

@keyframes compress-width {
  from {
    width: 100%;
  }
  to {
    width: 50%;
  }
}

@keyframes expand-width {
  from {
    width: 50%;
  }
  to {
    width: 100%;
  }
}

#edit-article-container hr {
  margin: 5% 0% 3% 0%;
}

textarea.ce-code__textarea.cdx-input {
  background-color: black;
  color: white;
}
