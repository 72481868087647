html {
  scroll-behavior: smooth;
}

body {
  font-family: Questrial;
}

a {
  text-underline-offset: 2px;
}

:root {
  --exhut-primary: #7f1bd0;
  --exhut-primary-light: #be83ee;
  --exhut-primary-extra-light: #ddb3ff;
  --exhut-black: #020202;
  --exhut-dark-grey: #7a7a7a;
  --exhut-green: #02a802;
  --exhut-light-grey0: #969696;
  --exhut-light-grey3: #e2e2e2;
  --exhut-light-grey5: #ececec;
  --exhut-orange: #e4a701;
  --exhut-red: #ff0000;
  --exhut-light-red: #ff7171;
  --exhut-dark-red: crimson;
  --exhut-total-green-light: #28ffd7;
  --exhut-total-green: #06b191;
  --exhut-white: #ffffff;
  --exhut-blue: #2196f3;
  --exhut-dark-blue: blue;
  --exhut-ramar-blue: #66baff;
  --exhut-yellow: #eeff00;
}

.splitleft {
  left: 0;
  width: 20%;
  height: 100%;
  position: fixed;
  top: 0;
  overflow-y: auto;
}

.splitright {
  right: 0;
  height: 100%;
  top: 0;
  position: fixed;
  width: 80%;
  overflow-y: auto;
}

.title {
  text-align: center;
  font-size: xx-large;
  margin: 10px 0px;
  font-weight: bold;
}

/* Remove the above .title after we start using this. This is usually used for title with extra
top margin for status message. */
.title-extra {
  text-align: center;
  font-size: 3vw;
  margin: 5% 0% 2% 0%;
  font-weight: bold;
}

.subtitle {
  text-align: center;
  font-size: larger;
  margin: 3% 0% 3% 0%;
  font-weight: bold;
}

.total {
  font-size: 3vw;
  font-weight: bold;
  text-align: center;
  color: var(--exhut-total-green);
}

.total-positive {
  font-size: 3vw;
  font-weight: bold;
  text-align: center;
  color: #28ffd7;
}

.total-negative {
  color: #ff8686;
  font-size: 3vw;
  font-weight: bold;
  text-align: center;
}

/* The total font size under tilesmall should be 1.5 */
.tile-small .total {
  font-size: 1.5vw;
}

.largerText {
  font-size: 20px;
}

.largeText {
  font-size: 18px;
}

.mediumText {
  font-size: 12px;
}

.smallText {
  font-size: 10px;
}

.boldText {
  font-weight: bold;
}

.errorMsg {
  color: var(--exhut-red);
}

.successMsg {
  color: var(--exhut-green);
}

.warnMsg {
  color: var(--exhut-orange);
}

.infoMsg {
  color: var(--exhut-dark-grey);
}

.blueMsg {
  color: var(--exhut-dark-blue);
}

/* Use this within the div of status msg */
.statusMsg {
  justify-content: center;
  margin: 10px 0px;
  display: flex;
  min-height: 1em;
}

.greeting {
  margin-left: 2em;
  font-size: 20px;
  margin-top: 3em;
  -webkit-animation: fadein 3s;
  animation: fadein 3s;
}

.greeting h1 {
  color: blue;
}

.greeting-body {
  margin-left: 2em;
  font-size: 1.3em;
  line-height: 2em;
}

.tableHeader {
  font-weight: bold;
}

/* Use this for multiple links which will be space-between */
.divHeaderSpread {
  margin: 0px 0px 40px 0px;
  display: flex;
  justify-content: space-between;
}

.divHeader {
  margin: 0px 0px 40px 0px;
  display: flex;
  justify-content: center;
}

.hyperlink {
  color: darkblue;
  margin-right: 5px;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.hyperlink-no-color {
  margin-right: 5px;
  cursor: pointer;
  text-decoration: underline;
}

.hyperlink-no-link {
  text-decoration: none;
}

.hyperlink-green {
  color: var(--exhut-total-green);
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 2px;
  font-size: larger;
}

.linkAsGreenButton {
  background-color: var(--exhut-green);
  color: white;
  padding: 7px;
  font-weight: 700;
  text-decoration: none;
  min-width: 70px;
  cursor: pointer;
}

.margin-center {
  margin: 0px 44px 40px 44px;
}

.app-body {
  margin: 40px 44px;
}

.app-body-no-vertical {
  margin: 0px 44px;
}

sup {
  color: red;
  font-weight: bold;
}

.iconSize {
  width: 20px;
  height: 20px;
}

.iconSizeSmall {
  width: 15px;
  height: 15px;
}

.divCenter {
  display: flex;
  text-align: center;
  justify-content: center;
}

.divAlignItemsOnly {
  display: flex;
  align-items: center;
}

.divCenterOnly {
  display: flex;
  justify-content: center;
  align-items: center;
}

.divCenterAlign {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.divLeft {
  display: flex;
  text-align: center;
  justify-content: flex-start;
}

.divRight {
  display: flex;
  text-align: center;
  justify-content: flex-end;
}

.divFlex {
  display: flex;
}

.divSpread {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divSpreadEvenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.divSpreadRight {
  display: flex;
  justify-content: flex-end;
}

.divColumn {
  display: flex;
  flex-direction: column;
}

.divColumnSpread {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.divColumnCenterAlign {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.divColumnCenterAlign > * {
  margin: 50px 0px 0px 0px;
}

.divRightAlign {
  align-items: flex-end;
}

/* Animations to fade the snackbar in and out */
@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeinstatus {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.status-msg-show {
  position: fixed;
  top: 0px;
  left: 50%;
  padding: 10px;
  width: 400px;
  animation-name: fadeinstatus;
  animation-duration: 1s;
  opacity: 0;
  height: 1em;
  color: var(--fnx-white);
  text-align: center;
  font-size: smaller;
  border-radius: 0px 0px 10px 10px;
}

.status-msg-hide {
  visibility: hidden;
  position: fixed;
  top: 0px;
}

.clickable {
  border: 0px solid var(--exhut-black);
  border-radius: 10px;
  padding: 10px;
  background-color: var(--exhut-light-grey5);
  margin: 0px 10px;
  cursor: pointer;
  font-size: xx-large;
  font-weight: bold;
}

.clickable-small {
  border: 0px solid var(--exhut-black);
  border-radius: 10px;
  padding: 10px;
  background-color: var(--exhut-light-grey5);
  margin: 0px 10px;
  cursor: pointer;
  font-weight: bold;
}

.hidden {
  visibility: hidden;
}

.topMargin10P {
  margin: 10% 0% 5% 0%;
}

.topMargin5P {
  margin: 5% 0%;
}

.topMargin2P {
  margin: 2% 0%;
}

.topMargin1P {
  margin: 1% 0% 2% 0%;
}

.topMargin0P {
  margin: 0% 0% 2% 0%;
}

select {
  font-size: 20px;
  padding: 5px;
  border-radius: 5px;
  border: 2px solid black;
}

.exhut-select {
  border: 0px;
  border-bottom: 1.5px solid cornflowerblue;
  border-radius: 0px;
  font-size: larger;
  min-width: 200px;
}

ul {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0em;
  margin-inline-end: 0em;
  padding-inline-start: 0em;
  list-style-type: none;
}

.div-header {
  margin: 40px 20px;
}

.spreadfull {
  width: -webkit-fill-available;
}

.hover-highlight:hover {
  background-color: cornsilk;
}

.mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.my-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}
.mt-10 {
  margin-top: 10px;
}

.mt-30 {
  margin-top: 30px;
}

.bigButtonGreen {
  background-color: var(--exhut-green);
  color: white;
  padding: 7px;
  text-decoration: none;
  width: 200px;
  font-weight: bold;
  font-size: large;
  border: 2px solid var(--exhut-green);
  margin: 0px;
  text-align: center;
  cursor: pointer;
}

.bigButtonGreen:hover {
  border: 2px solid var(--exhut-blue);
}

.bigButtonGreen:disabled {
  background-color: #dadada;
  border: 0px solid #95a296;
  color: rgb(150, 149, 149);
}

.iconSize {
  width: 20px;
  height: 20px;
}

.iconSizeLarge {
  width: 30px;
  height: 30px;
}

.iconSizeSmall {
  width: 15px;
  height: 15px;
}

.p-8 {
  padding: 8px;
}

.p-12 {
  padding: 12px;
}
