.greeting-infinite {
  font-size: 20px;
  -webkit-animation: fadein 1.5s infinite;
  animation: fadein 1.5s infinite;
  color: #35ff35;
}

.trending-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
}

.trending-header a {
  color: var(--exhut-total-green-light);
  text-underline-offset: 2px;
}
