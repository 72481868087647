.tile {
  background-color: lightgrey;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border: 2px solid black;
  height: 200px;
}

.folder-tile {
  width: 100%;
  min-width: 200px;
  height: 100%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.asset-context-menu-item {
  padding: 5px;
}
