#instructions-container {
  width: 100%;
}
#instructions-container #tile-container {
  margin-top: 5%;
}

#instructions-container .tile {
  padding: 10px 20px;
  width: 30%;
  border: 1px solid black;
  margin: 0px 5%;
  border-radius: 5%;
  color: white;
  display: flex;
  flex-direction: column;
}

#instructions-container .tile p {
  font-size: large;
}
