#home-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0% 1% 1% 2%;
  height: 100%;
}

#home-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2%;
}

#home-component > div {
  margin-bottom: 40px;
}
